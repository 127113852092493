import React, { useState, useEffect } from 'react';
import BackgroundSuperbet from '../../assets/superbet/fundo.png';
import BackgroundWill from '../../assets/will/fundo.png';
import BackgroundWillMobile from "../../assets/will/willMbl.png";
import BackgroundApostaGanha from '../../assets/apostaganha/fundo.png';
import BackgroundApostaGanhaMobile from '../../assets/apostaganha/mobile.png';
import BackgroundXpbet from "../../assets/xpbet/fundo.png";
import BackgroundXpbetMobile from "../../assets/xpbet/mobile.png";
import styles from './styles';
import axios from 'axios';

const PLATFORMS = {
    will: {
        backgroundImage: { desktop: BackgroundWill, mobile: BackgroundWillMobile },
        progressBarStyle: styles.progressBarWill,
        progressTextStyle: styles.loadingMessage,
        redirectUrl: (source) => `https://igoal.go2cloud.org/aff_c?offer_id=6041&aff_id=3166&source=${source}`,
    },
    superbet: {
        backgroundImage: { desktop: BackgroundSuperbet, mobile: BackgroundSuperbet },
        progressBarStyle: styles.progressBar,
        progressTextStyle: styles.loadingMessage,
        redirectUrl: (source) => `https://wlsuperbet.adsrv.eacdn.com/C.ashx?btag=a_5800b_430c_&affid=678&siteid=5800&adid=430&c=${source}`,
    },
    apostaganha: {
        backgroundImage: { desktop: BackgroundApostaGanha, mobile: BackgroundApostaGanhaMobile },
        progressBarStyle: styles.progressBarApostaGanha,
        progressTextStyle: styles.loadingMessage,
        redirectUrl: (source) => `https://apostaganha.bet.br/auth/login?v2=true&utm_source=${source}`,
    },
    xpbet: {
        backgroundImage: { desktop: BackgroundXpbet, mobile: BackgroundXpbetMobile },
        progressBarStyle: styles.progressBarApostaGanha,
        progressTextStyle: styles.loadingMessageWhite,
        redirectUrl: (source) => `https://xp.bet.br/adv/?btag=2296082_l351623_nId4353&utm_campaign=${source}&uniqueid=1`,
    },
};

const Home = () => {
    const [progress, setProgress] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [backgroundImage, setBackgroundImage] = useState('');
    const [progressBarStyle, setProgressBarStyle] = useState({});
    const [progressTextStyle, setProgressTextStyle] = useState({});

    function generate() {
        return 'event_' + Date.now();
    }

    useEffect(() => {
        const getPathSegments = () => {
            const path = window.location.pathname;
            console.log(path);
            const segments = path.split('/').filter(Boolean);
            return segments;
        };

        const segments = getPathSegments();

        if (segments.length < 2) {
            setLoadingMessage('❌ URL inválido!');
            return;
        }

        const [platform, source] = segments;
        const platformConfig = PLATFORMS[platform];

        if (!platformConfig) {
            setLoadingMessage('❌ Plataforma desconhecida!');
            return;
        }

        const isMobile = window.innerWidth <= 768;
        setBackgroundImage(isMobile ? platformConfig.backgroundImage.mobile : platformConfig.backgroundImage.desktop);
        setProgressBarStyle(platformConfig.progressBarStyle);
        setProgressTextStyle(platformConfig.progressTextStyle);

        const sanitizedSource = source.replace(/[_\.]/g, '');
        const redirectUrl = platformConfig.redirectUrl(sanitizedSource);

        const referrer = document.referrer || '';
        const isFromInstagram = referrer.includes('instagram.com');


        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    clearInterval(interval);
                    setLoadingMessage('✅ Concluído!');

                    if (isFromInstagram) {
                        axios.post("http://46.202.151.136/vps-virawapp/api/event-redirect.php", {
                            username: source,
                            instagram: true,
                        })
                    }

                    if (typeof window.fbq === 'function') {
                        const eventId = generate();
                        window.fbq('track', platform, { eventID: eventId });
                    }

                    setTimeout(() => {
                        window.location.replace(redirectUrl);
                    }, 100);

                    return 100;
                }
                const diff = 100 / 40;
                const newProgress = Math.min(oldProgress + diff, 100);
                updateLoadingMessage(newProgress);
                return newProgress;
            });
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const updateLoadingMessage = (progress) => {
        if (progress < 40.33) {
            setLoadingMessage('⏳ Carregando página...');
        } else if (progress < 72.66) {
            setLoadingMessage('🔓 Conexão segura...');
        } else if (progress < 85) {
            setLoadingMessage('✅ Concluído!');
        }
    };

    return (
        <div style={{ ...styles.backgroundContainer, backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
            <div style={styles.progressContainer}>
                <div className="loading-message" style={{ ...styles.loadingMessage, ...progressTextStyle }}>{loadingMessage}</div>
                <div style={styles.progressBarContainer}>
                    <div style={{ ...progressBarStyle, width: `${progress}%` }} />
                </div>
            </div>
        </div>
    );
};

export default Home;